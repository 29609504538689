export const AddOutcomeNote = {
  4: (state: any) => {
    return {
      ...state,
      cases: {
        note: {
          note: "",
        },
      },
    };
  },
};

export const RebuildCodeTable = {
  11: (state: any) => {
    return {
      ...state,
      codeTables: {
        agency: [],
        attractant: [],
        "complaint-status": [],
        "complaint-type": [],
        "nature-of-complaint": [],
        species: [],
        regions: [],
        zones: [],
        communities: [],
        "area-codes": [],
        "reported-by": [],
        justification: [],
        "assessment-type": [],
        "prevention-type": [],
        sex: [],
        age: [],
        "threat-level": [],
        "conflict-history": [],
        "ear-tag": [],
        "wildlife-outcomes": [],
        drugs: [],
        "drug-methods": [],
        "drug-remaining-outcomes": [],
        equipment: [],
        "gir-type": [],
        violation: [],
      },
    };
  },
};

export const AddWebEOCChangeCount = {
  9: (state: any) => {
    return {
      ...state,
      complaints: {
        webeocChangeCount: 0,
      },
    };
  },
};

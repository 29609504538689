export const CODE_TABLE_TYPES = {
  AGENCY: "agency",
  ATTRACTANT: "attractant",
  COMPLAINT_STATUS: "complaint-status",
  COMPLAINT_TYPE: "complaint-type",
  NATURE_OF_COMPLAINT: "nature-of-complaint",
  SPECIES: "species",
  VIOLATIONS: "violation",
  REGIONS: "regions",
  ZONES: "zones",
  COMMUNITIES: "communities",
  AREA_CODES: "area-codes",
  REPORTED_BY: "reported-by",
  JUSTIFICATION: "justification",
  ASSESSMENT_TYPE: "assessment-type",
  PREVENTION_TYPE: "prevention-type",
  SEX: "sex",
  AGE: "age",
  THREAT_LEVEL: "threat-level",
  CONFLICT_HISTORY: "conflict-history",
  EARS: "ear-tag",
  WILDLIFE_OUTCOMES: "wildlife-outcomes",
  DRUGS: "drugs",
  DRUG_METHOD_USE: "drug-methods",
  REMAINING_DRUG_USE: "drug-remaining-outcomes",
  EQUIPMENT: "equipment",
  GIR_TYPE: "gir-type",
  DISCHARGE: "discharge",
  NON_COMPLIANCE: "non-compliance",
  SECTOR: "sector",
  SCHEDULE: "schedule",
  DECISION_TYPE: "decision-type",
  TEAM: "team",
  COMPLAINT_METHOD_RECEIVED: "complaint-method-received-codes",
  LEAD_AGENCY: "lead-agency",
  SCHEDULE_SECTOR: "schedule-sector",
  SCHEDULE_SECTOR_TYPE: "schedule-sector-type",

  //  ORGANIZATION_UNIT_TYPE: "organization-unit-type",
  //  ORGANIZATION_UNIT: "organization-unit",
  //  PERSON_TYPE: "person-complaint",
};
